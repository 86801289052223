<template>
  <b-card class="p-1">
    <validation-observer 
    ref="simpleRules"
    #default="{invalid}"
    >
    <!-- form -->
    <b-form
      method="POST"
      @submit.prevent="validationForm"
    >
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label="Senha Atual"
            label-for="senha-antiga"
          >
            <validation-provider
              #default="{ errors }"
              name="senhaAntiga"
              vid="senhaAntiga"
              rules="required"
            >
              <b-input-group 
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="senha-antiga"
                  v-model="senhaAntiga"
                  name="senha-antiga"
                  :type="passwordFieldTypeOld"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Senha Atual"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="senha-nova"
            label="Senha Nova"
          >
            <validation-provider
              #default="{ errors }"
              name="senhaNova"
              vid="senhaNova"
              rules="required"
            >
            <b-input-group 
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="senha-nova"
                v-model="senhaNova"
                :type="passwordFieldTypeNew"
                :state="errors.length > 0 ? false:null"
                name="senha-nova"
                placeholder="Senha Nova"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="senha-nova-confirmacao"
            label="Confirmação Senha Nova"
          >
            <validation-provider
              #default="{ errors }"
              vid="senhaNovaConfirmacao"
              name="senhaNovaConfirmacao"
              rules="required|confirmed:senhaNova"
            >
            <b-input-group 
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="senha-nova-confirmacao"
                v-model="senhaNovaConfirmacao"
                :type="passwordFieldTypeRetype"
                :state="errors.length > 0 ? false:null"
                name="senha-nova-confirmacao"
                placeholder="Senha Nova"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            type="submit"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :disabled="invalid || respostaApi"
          >
            Salvar
            <b-spinner
              v-if="respostaApi"
              small
              label="Carregando"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            class="mt-1"
          >
            Limpar
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
    </validation-observer>
  </b-card>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import { required } from '@validations'

import {
  BButton, BSpinner, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'

// ERROS DOS CAMPOS
import { localize } from 'vee-validate'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      senhaAntiga: {
        required: 'A senha atual é obrigatória.',
      },
      senhaNova: {
        required: 'A senha é obrigatória.',
      },
      senhaNovaConfirmacao: {
        required: 'Confirmação obrigatória.',
        confirmed: 'A senhas não estão iguais.',
      },
    },
  },
})

export default {
  components: {
    BButton,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      senhaAntiga: '',
      senhaNova: '',
      senhaNovaConfirmacao: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      respostaApi: false,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },


    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          this.respostaApi = true
          
          useJwt.usuarioTrocarSenha({
            usuarioId: JSON.parse(localStorage.getItem('usuarioData'))?.sub,
            senhaAntiga: this.senhaAntiga,
            novaSenha: this.senhaNova
          })
          .then(response => {
            
            this.$swal({
              title: 'Senha Alterada!',
              text: 'Sua senha foi trocada com sucesso.',
              icon: 'success',
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {

                this.$router.go()

              } else {

                this.$router.go()

              }
            })
          })
          .catch(error => {
            //console.log( error.response );
            this.$swal({
              title: 'Erro!',
              text: error.response.data.message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

          })
          .finally(() => {
            this.respostaApi = false
          })


          
        }
      })
    },

  },
}
</script>
