<template>
  <b-card class="p-1">
    <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <p
              class="text-center"
            >
              <b-spinner
                  v-if="carregamentoDadosApi"
                  small
                  label="Carregando"
                  class="text-primary"
              />
            </p>
            <b-form
              v-if="!carregamentoDadosApi"
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- username -->
              <b-form-group
                label="Nome Completo"
                label-for="usuarioNome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usuarioNome"
                  vid="usuarioNome"
                  rules="required"
                >
                  <b-form-input
                    id="usuarioNome"
                    v-model="usuarioNome"
                    name="usuarioNome"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Nome e Sobrenome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-row>
                <b-col>
                  <!-- cpf -->
                  <b-form-group
                    label="Data Nascimento"
                    label-for="usuarioDtNascimento"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="usuarioDtNascimento"
                      vid="usuarioDtNascimento"
                      rules="required"
                    >
                      <the-mask
                        id="usuarioDtNascimento"
                        v-model="usuarioDtNascimento"
                        :state="errors.length > 0 ? false:null"
                        :mask="['##/##/####']"
                        :masked="false"
                        class="form-control"
                        placeholder="DD/MM/AAAA"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="CPF"
                    label-for="usuarioCpf"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="usuarioCpf"
                      vid="usuarioCpf"
                      rules="required|cpf"
                    >
                      <the-mask
                        id="usuarioCpf"
                        v-model="usuarioCpf"
                        :state="errors.length > 0 ? false:null"
                        :mask="['###.###.###-##']"
                        class="form-control"
                        placeholder="000.000.000-00"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row v-if="tipoPerfil == 'ROLE_COMPRADOR' || tipoPerfil == 'ROLE_GESTOR'">
                <b-col>
                  <!-- CNPJ -->
                  <b-form-group
                    label="Razão Social"
                    label-for="usuarioRazaoSocial"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="usuarioRazaoSocial"
                      vid="usuarioRazaoSocial"
                    >
                      <b-form-input
                        id="usuarioRazaoSocial"
                        v-model="usuarioRazaoSocial"
                        name="usuarioRazaoSocial"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Razão Social"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="CNPJ"
                    label-for="usuarioCnpj"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="usuarioCnpj"
                      vid="usuarioCnpj"
                      rules="cnpj"
                    >
                      <the-mask
                        id="usuarioCnpj"
                        v-model="usuarioCnpj"
                        :state="errors.length > 0 ? false:null"
                        :mask="['##.###.###/####-##']"
                        class="form-control"
                        placeholder="00.000.000/0000-00"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- celular -->
              <b-form-group
                label="WhatsApp"
                label-for="usuarioCelular"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usuarioCelular"
                  vid="usuarioCelular"
                  rules="required"
                >
                  <the-mask
                    id="usuarioCelular"
                    v-model="usuarioCelular"
                    :state="errors.length > 0 ? false:null"
                    :mask="['(##) #####-####']"
                    class="form-control"
                    placeholder="(00) 00000-0000"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="usuarioEmail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="usuarioEmail"
                  vid="usuarioEmail"
                  rules="required|email"
                >
                  <b-form-input
                    id="usuarioEmail"
                    v-model="usuarioEmail"
                    name="usuarioEmail"
                    :state="errors.length > 0 ? false:null"
                    placeholder="email@exemplo.com"
                    autocomplete="off"
                    readonly
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                type="submit"
                :disabled="invalid || respostaApi"
              >
                Salvar
                <b-spinner
                  v-if="respostaApi"
                  small
                  label="Carregando"
                />
              </b-button>
            </b-form>
          </validation-observer>
  </b-card>
</template>

<script>

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

import {
  BSpinner, BFormFile, BAlert, BIconEnvelope, BCard, BMedia, BMediaAside, BMediaBody, BFormInvalidFeedback, IconsPlugin, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import useJwt from '@/auth/jwt/useJwt'

import DisableAutocomplete from 'vue-disable-autocomplete'

import { TheMask } from 'vue-the-mask'

import { cpf, cnpj, cpfOrCnpj } from 'vee-cpf-cnpj'

// ERROS DOS CAMPOS
import { localize } from 'vee-validate'

extend('cpf', cpf)
extend('cnpj', cnpj)
extend('cpf_or_cnpj', cpfOrCnpj)

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      usuarioNome: {
        required: 'O nome é obrigatório.',
      },
      usuarioCpf: {
        required: 'O cpf é obrigatório.',
        cpf: 'O cpf é inválido.',
      },
      usuarioCnpj: {
        cnpj: 'O cnpj é inválido.',
      },
      usuarioDtNascimento: {
        required: 'A data é obrigatória.'
      },
      usuarioCidade: {
        required: 'A cidade é obrigatória.',
      },
      usuarioEstado: {
        required: 'O estado é obrigatório.',
      },
      usuarioCelular: {
        required: 'O WhatsApp é obrigatório.',
      },
      usuarioEmail: {
        required: 'O email é obrigatório.',
        email: 'Email inválido.',
      },
    },
  },
})


export default {
  components: {
    BSpinner,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    // autocomplete off
    DisableAutocomplete,
    TheMask,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      usuario: [],
      usuarioNome: null,
      usuarioDtNascimento: null,
      usuarioCpf: null,
      usuarioRazaoSocial: null,
      usuarioCnpj: null,
      usuarioCelular: null,
      usuarioEmail: null,
      respostaApi: false,
      carregamentoDadosApi: false,
      tipoPerfil: '',
    }
  },
  mounted() {
    // this.formattedRazaoSocial()
    this.usuarioDados()
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },

    // formattedRazaoSocial() {
    //   if (this.usuarioRazaoSocial == null || this.usuarioRazaoSocial == "NULL" || this.usuarioRazaoSocial == NULL || this.usuarioRazaoSocial == "null") {
    //     this.usuarioRazaoSocial = ''
    //   }
    // },

    usuarioDados() {
      this.carregamentoDadosApi = true;
      this.tipoPerfil = JSON.parse(localStorage.getItem('usuarioData'))?.authority || '';
      
      switch (this.tipoPerfil) {
        case "ROLE_COMPOSITOR":
          useJwt.usuarioDados()
            .then(response => {
              const usuario = response.data;
              this.usuarioNome = usuario.nome;
              this.usuarioDtNascimento = usuario.dataNascimento;
              this.usuarioCpf = usuario.cpf;
              this.usuarioCelular = usuario.celular;
              this.usuarioEmail = usuario.email;
              this.carregamentoDadosApi = false;
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
              this.carregamentoDadosApi = false;
            });
          break;

        case "ROLE_COMPRADOR":
          useJwt.usuarioDadosComprador()
            .then(response => {
              const usuario = response.data;
              this.usuarioNome = usuario.nome;
              this.usuarioDtNascimento = usuario.dataNascimento;
              this.usuarioCpf = usuario.cpf;
              this.usuarioRazaoSocial = usuario.razaoSocial;
              this.usuarioCnpj = usuario.cnpj;
              this.usuarioCelular = usuario.celular;
              this.usuarioEmail = usuario.email;
              this.carregamentoDadosApi = false;
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
              this.carregamentoDadosApi = false;
            });
          break;

          case "ROLE_GESTOR":
          useJwt.usuarioDadosGestor({
            usuarioId: JSON.parse(localStorage.getItem('usuarioData'))?.sub
          })
            .then(response => {
              const usuario = response.data;
              this.usuarioNome = usuario.nome;
              this.usuarioDtNascimento = usuario.dataNascimento;
              this.usuarioCpf = usuario.cpf;
              this.usuarioRazaoSocial = usuario.razaoSocial;
              this.usuarioCnpj = usuario.cnpj;
              this.usuarioCelular = usuario.celular;
              this.usuarioEmail = usuario.email;
              this.carregamentoDadosApi = false;
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
              this.carregamentoDadosApi = false;
            });
          break;

        default:
          // Caso não seja nem ROLE_COMPOSITOR nem ROLE_COMPRADOR
          console.error('Tipo de perfil desconhecido');
          this.carregamentoDadosApi = false;
          break;
      }
    },


    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.respostaApi = true;
          this.tipoPerfil = JSON.parse(localStorage.getItem('usuarioData'))?.authority || '';
          const usuarioId = JSON.parse(localStorage.getItem('usuarioData'))?.sub;
          
          switch (this.tipoPerfil) {
            case "ROLE_COMPOSITOR":
              useJwt.usuarioEditar({
                usuarioId,
                nome: this.usuarioNome,
                cpf: this.usuarioCpf,
                dataNascimento: this.usuarioDtNascimento,
                celular: this.usuarioCelular,
              })
                .then(response => {
                  this.$swal({
                    title: 'Informações Salvas!',
                    text: 'Suas informações foram atualizadas com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    this.$router.go();
                  });
                })
                .catch(error => {
                  this.$swal({
                    title: 'Erro!',
                    text: error.response.data.message,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  });
                })
                .finally(() => {
                  this.respostaApi = false;
                });
              break;

            case "ROLE_COMPRADOR":
              useJwt.usuarioCompradorEditar({
                usuarioId,
                nome: this.usuarioNome,
                razaoSocial: this.usuarioRazaoSocial ? this.usuarioRazaoSocial.trim() : null,
                cpf: this.usuarioCpf,
                cnpj: this.usuarioCnpj,
                dataNascimento: this.usuarioDtNascimento,
                celular: this.usuarioCelular,
              })
                .then(response => {
                  this.$swal({
                    title: 'Informações Salvas!',
                    text: 'Suas informações foram atualizadas com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    this.$router.go();
                  });
                })
                .catch(error => {
                  this.$swal({
                    title: 'Erro!',
                    text: error.response.data.message,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  });
                })
                .finally(() => {
                  this.respostaApi = false;
                });
              break;

            case "ROLE_GESTOR":
              useJwt.usuarioGestorEditar({
                usuarioId,
                nome: this.usuarioNome,
                razaoSocial: this.usuarioRazaoSocial ? this.usuarioRazaoSocial.trim() : null,
                cpf: this.usuarioCpf,
                cnpj: this.usuarioCnpj,
                dataNascimento: this.usuarioDtNascimento,
                celular: this.usuarioCelular,
              })
                .then(response => {
                  this.$swal({
                    title: 'Informações Salvas!',
                    text: 'Suas informações foram atualizadas com sucesso.',
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    this.$router.go();
                  });
                })
                .catch(error => {
                  this.$swal({
                    title: 'Erro!',
                    text: error.response.data.message,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  });
                })
                .finally(() => {
                  this.respostaApi = false;
                });
              break;

            default:
              // Caso não seja nem ROLE_COMPOSITOR nem ROLE_COMPRADOR
              console.error('Tipo de perfil desconhecido');
              this.respostaApi = false;
              break;
          }
        }
  });
}

    
    
    //
  },
}
</script>
