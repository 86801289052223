<template>
  <b-card class="p-1">

    <!-- form -->
    <validation-observer
        #default="{invalid}"
        ref="enderecoForms"
      >
        <p
          class="text-center"
        >
          <b-spinner
              v-if="carregamentoDadosApi"
              small
              label="Carregando"
              class="text-primary"
          />
        </p>
        <!-- Form -->
        <b-form
          v-if="!carregamentoDadosApi"
          class="mt-2"
          @submit.prevent="register"
        >

          <b-row>

            <b-col
              cols="4"
            >

              <!-- Cep -->
              <b-form-group
                label="CEP"
                label-for="enderecoCepLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoCep"
                  rules="required"
                >
                  <the-mask
                    id="enderecoCep"
                    v-model="enderecoCep"
                    :mask="['#####-###']"
                    :masked="false"
                    :state="errors.length > 0 ? false:null"
                    placeholder="00000-000"
                    class="form-control"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Cep -->

            </b-col>

            <b-col>

              <!-- Endereço -->
              <b-form-group
                label="Endereço"
                label-for="enderecoLogradouroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoLogradouro"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoLogradouro"
                    v-model="enderecoLogradouro"
                    name="enderecoLogradouro"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Endereço -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="4"
            >

              <!-- Número -->
              <b-form-group
                label="Número"
                label-for="enderecoNumeroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoNumero"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoNumero"
                    v-model="enderecoNumero"
                    name="enderecoNumero"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Número -->

            </b-col>

            <b-col>

              <!-- Bairro -->
              <b-form-group
                label="Bairro"
                label-for="enderecoBairroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoBairro"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoBairro"
                    v-model="enderecoBairro"
                    name="enderecoBairro"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Bairro -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="12"
            >

              <!-- Complemento -->
              <b-form-group
                label="Complemento"
                label-for="enderecoComplementoLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoComplemento"
                >

                  <b-form-input
                    id="enderecoComplemento"
                    v-model="enderecoComplemento"
                    name="enderecoComplemento"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Número -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="8"
            >

              <!-- Cidade -->
              <b-form-group
                label="Cidade"
                label-for="enderecoCidadeLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoCidade"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoCidade"
                    v-model="enderecoCidade"
                    name="enderecoCidade"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Cidade -->

            </b-col>

            <b-col>

              <!-- Estado -->
              <b-form-group
                label="Estado"
                label-for="enderecoEstadoLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoEstado"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoEstado"
                    v-model="enderecoEstado"
                    name="enderecoEstado"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Estado -->

            </b-col>

          </b-row>


          <b-button
            variant="primary"
            type="submit"
            :disabled="invalid || respostaApi"
          >
            Salvar
            <b-spinner
              v-if="respostaApi"
              small
              label="Carregando"
            />
          </b-button>

        </b-form>
      </validation-observer>

  </b-card>
</template>

<script>

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

import {
  BSpinner, BFormFile, BAlert, BIconEnvelope, BCard, BMedia, BMediaAside, BMediaBody, BFormInvalidFeedback, IconsPlugin, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormSelect, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'

import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import useJwt from '@/auth/jwt/useJwt'

import DisableAutocomplete from 'vue-disable-autocomplete'

import { TheMask } from 'vue-the-mask'

// ERROS DOS CAMPOS
import { localize } from 'vee-validate'

localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      enderecoCep: {
        required: 'CEP é obrigatório.',
      },
      enderecoLogradouro: {
        required: 'Logradouro é obrigatório.',
      },
      enderecoNumero: {
        required: 'Número é obrigatório.',
      },
      enderecoBairro: {
        required: 'Bairro é obrigatório.',
      },
      enderecoCidade: {
        required: 'Cidade é obrigatória.',
      },
      enderecoEstado: {
        required: 'Estado é obrigatório.',
      },
    },
  },
})


export default {
  components: {
    BSpinner,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    // autocomplete off
    DisableAutocomplete,
    TheMask,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      completarEndereco: null,
      enderecoId: '',
      enderecoCep: '',
      enderecoLogradouro: '',
      enderecoNumero: '',
      enderecoBairro: '',
      enderecoComplemento: '',
      enderecoCidade: '',
      enderecoEstado: '',
      respostaApi: false,
      carregamentoDadosApi: false,
    }
  },
  mounted() {
    // Verificar Endereço
    const usuarioData = JSON.parse(localStorage.getItem('usuarioData'))
    const completarCadastro = usuarioData.completarCadastro

    this.completarEndereco = completarCadastro

    if (completarCadastro === false) {
      this.usuarioEnderecoDados()
      //alert('Já tem endereço')
    }

  },
  watch: {
    enderecoCep(novoValor, valorAntigo) {
      if (novoValor.length === 8 && valorAntigo !== '') {
        fetch(`https://viacep.com.br/ws/${novoValor}/json/`)
          .then(response => response.json())
          .then(data => {
            this.enderecoLogradouro = data.logradouro;
            this.enderecoBairro = data.bairro;
            this.enderecoCidade = data.localidade;
            this.enderecoEstado = data.uf;
          })
          .catch(error => console.error(error));
      }
    },
    
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },

    // Consultar CEP

    consultarCEP() {
      fetch(`https://viacep.com.br/ws/${this.cep}/json/`)
        .then(response => response.json())
        .then(data => {
          this.enderecoLogradouro = data.logradouro;
          this.enderecoBairro = data.bairro;
          this.enderecoCidade = data.localidade;
          this.enderecoEstado = data.uf;
        })
        .catch(error => console.error(error));
    },
    
    // Consultar CEP

    usuarioEnderecoDados() {

      this.carregamentoDadosApi = true

      useJwt.usuarioEnderecoDados({
      })
      .then(response => {


        const endereco = response.data

        this.enderecoId = endereco.id
        this.enderecoCep = endereco.cep
        this.enderecoNumero = endereco.numero
        this.enderecoLogradouro = endereco.logradouro
        this.enderecoNumero = endereco.numero
        this.enderecoBairro = endereco.bairro
        this.enderecoComplemento = endereco.complemento
        this.enderecoCidade = endereco.cidade
        this.enderecoEstado = endereco.estado

        this.carregamentoDadosApi = false

       // alert(this.usuarioDtNascimento)
        
      })
      .catch(error => {
        //console.log( error.response );
        this.$swal({
          title: 'Erro!',
          text: error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })

      })
      // .finally(() => {
      //   this.carregamentoDadosApi = false
      // })

    },

    register() {
      this.$refs.enderecoForms.validate().then(success => {
        if (success) {
          // console.log( this.usuarioCelular );return false;

          // VERIFICAR EDITAR OU CADASTRAR

          if(this.completarEndereco === false) {

            this.respostaApi = true

            useJwt.usuarioEnderecoEditar({
              id: this.enderecoId,
              logradouro: this.enderecoLogradouro,
              numero: this.enderecoNumero,
              bairro: this.enderecoBairro,
              cidade: this.enderecoCidade,
              estado: this.enderecoEstado,
              complemento: this.enderecoComplemento,
              cep: this.enderecoCep,
            })
              .then(response => {
                //console.log( response )

                this.$swal({
                title: 'Endereço Atualizado!',
                text: 'Suas informações de endereço foram atualizadas com sucesso.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {

                  this.$router.go()

                } else {

                  this.$router.go()

                }
              })

              })
              .catch(error => {
                // console.log( error.response );

                this.$swal({
                  title: 'Erro!',
                  text: error.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })

                // this.$refs.registerForm.setErrors(error.response.data.error)
              })
              .finally(() => {
                this.respostaApi = false
              })
          
          } else {

            this.respostaApi = true

            useJwt.compositorEndereco({
              logradouro: this.enderecoLogradouro,
              numero: this.enderecoNumero,
              bairro: this.enderecoBairro,
              cidade: this.enderecoCidade,
              estado: this.enderecoEstado,
              complemento: this.enderecoComplemento,
              cep: this.enderecoCep,
            })
            .then(response => {

              const usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
              usuarioData.completarCadastro = false;
              localStorage.setItem('usuarioData', JSON.stringify(usuarioData));

              this.$swal({
                title: 'Endereço cadastrado!',
                text: 'Seu cadastrado foi atualizado.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {

                this.$router.go()

                } else {

                this.$router.go()

                }
              })

            })
            .catch(error => {

              this.$swal({
                title: 'Erro!',
                text: 'Ocorreu um erro. Tente novamente.',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })

            })
            .finally(() => {
              this.respostaApi = false
            })

          }

          // VERIFICAR EDITAR OU CADASTRAR
          
        }
      })
    },

  },
}
</script>
