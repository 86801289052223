<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- Geral Tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Informações</span>
      </template>

      <conta-configuracao-geral/>
    </b-tab>
    <!--/ Geral Tab -->

    <!-- Geral Tab -->
    <b-tab>

    <!-- title -->
    <template #title>
      <feather-icon
        icon="MapPinIcon"
        size="18"
        class="mr-50"
      />
      <span class="font-weight-bold">Endereço</span>
    </template>

    <conta-configuracao-endereco/>
    </b-tab>
    <!--/ Geral Tab -->

    <!-- Alterar Senha Tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Alterar Senha</span>
      </template>

      <conta-configuracao-senha />
    </b-tab>
    <!--/ Alterar Senha Tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ContaConfiguracaoGeral from './ContaConfiguracaoGeral.vue'
import ContaConfiguracaoEndereco from './ContaConfiguracaoEndereco.vue'
import ContaConfiguracaoSenha from './ContaConfiguracaoSenha.vue'

export default {
  components: {
    BTabs,
    BTab,
    ContaConfiguracaoGeral,
    ContaConfiguracaoEndereco,
    ContaConfiguracaoSenha,
  },
  data() {
    return {
      tipoPerfil: ''
    }
  },
  mounted() {
    this.tipoPerfil = JSON.parse(localStorage.getItem('usuarioData'))?.authority || '';
  }

}
</script>
